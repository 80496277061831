const { createClient } = require('@supabase/supabase-js');

const supabaseUrl = process.env.SUPABASE_URL;
const supabaseKey = process.env.SUPABASE_KEY;

let supabase;

if (supabaseUrl && supabaseKey) {
  console.log('Supabase URL:', supabaseUrl);
  console.log('Supabase Key:', supabaseKey.substring(0, 5) + '...');
  supabase = createClient(supabaseUrl, supabaseKey);
} else {
  console.warn('Supabase URL and Key are not defined in environment variables. Some features may not work.');
  supabase = null;
}

module.exports = { supabase };
