import React, { useState, useEffect, useRef } from 'react';
import { FaBuilding, FaPhone, FaEnvelope, FaUpload, FaFile, FaTrash, FaMapMarkerAlt, FaUser, FaWhatsapp } from 'react-icons/fa';
import { createClient } from '@supabase/supabase-js';

function AI() {
  const [activeSubmenu, setActiveSubmenu] = useState('prompt');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [whatsappMessage, setWhatsappMessage] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [userName, setUserName] = useState('');
  const promptRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [transcriptions, setTranscriptions] = useState([]);
  const [userId, setUserId] = useState(null);
  const [supabase, setSupabase] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const supabaseUrl = process.env.SUPABASE_URL;
    const supabaseKey = process.env.SUPABASE_KEY;
    console.log('Supabase URL:', supabaseUrl);
    console.log('Supabase Key:', supabaseKey);

    if (supabaseUrl && supabaseKey) {
      const supabaseClient = createClient(supabaseUrl, supabaseKey);
      setSupabase(supabaseClient);
      console.log('Supabase client created');
    } else {
      console.error('Supabase URL or Key is missing');
    }

    const currentUserId = localStorage.getItem('userId');
    console.log('Current User ID:', currentUserId);
    
    if (currentUserId) {
      setUserId(currentUserId);
      loadUserData(currentUserId, supabaseClient);
    } else {
      console.error('User ID is missing');
      // Aquí puedes manejar el caso cuando el ID de usuario no está disponible
      // Por ejemplo, redirigir al usuario a la página de inicio de sesión
      // o mostrar un mensaje de error
    }
  }, []);

  const loadUserData = async (userId, supabaseClient) => {
    try {
      const { data: userData, error: userError } = await supabaseClient
        .from('usuarios')
        .select('id')
        .eq('id', userId)
        .single();

      if (userError) throw userError;

      if (userData) {
        console.log('User data loaded:', userData);
        loadUserFiles(userData.id, supabaseClient);
        loadUserTranscriptions(userData.id, supabaseClient);
      } else {
        console.error('User not found');
        // Manejar el caso cuando el usuario no se encuentra en la base de datos
      }
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  };

  useEffect(() => {
    if (userId) {
      console.log('User ID is now available:', userId);
      // Aquí puedes realizar acciones adicionales que dependen del ID de usuario
    }
  }, [userId]);

  useEffect(() => {
    if (supabase && userId) {
      console.log('Supabase client and userId are available');
    } else {
      console.log('Supabase client or userId is not available');
    }
  }, [supabase, userId]);

  const loadUserFiles = async (userId, supabaseClient) => {
    const { data, error } = await supabaseClient
      .from('user_files')
      .select('*')
      .eq('user_id', userId);

    if (error) {
      console.error('Error al cargar los archivos:', error);
    } else {
      setUploadedFiles(data || []);
    }
  };

  const loadUserTranscriptions = async (userId, supabaseClient) => {
    const { data, error } = await supabaseClient
      .from('transcriptions')
      .select('*')
      .eq('user_id', userId);

    if (error) {
      console.error('Error al cargar las transcripciones:', error);
    } else {
      setTranscriptions(data || []);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && supabase && userId) {
      try {
        // Crear un nombre de archivo único
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
        const filePath = `${userId}/${fileName}`;

        // Subir el archivo a Supabase Storage
        const { data, error } = await supabase.storage
          .from('user_files')
          .upload(filePath, file);

        if (error) throw error;

        // Insertar la información del archivo en la base de datos
        const { data: fileData, error: fileError } = await supabase
          .from('user_files')
          .insert({
            user_id: userId,
            file_name: file.name,
            file_size: file.size,
            file_path: data.path
          })
          .select();

        if (fileError) throw fileError;

        // Actualizar el estado local
        setUploadedFiles(prevFiles => [...prevFiles, fileData[0]]);

        // Procesar el archivo con vector
        await processFileWithVector(fileData[0].id, data.path);

        console.log('Archivo subido con éxito:', fileData[0]);
      } catch (error) {
        console.error('Error al subir el archivo:', error);
      }
    }
  };

  const removeFile = async (id) => {
    if (supabase && userId) {
      try {
        // Encontrar el archivo a eliminar
        const fileToRemove = uploadedFiles.find(file => file.id === id);
        if (fileToRemove) {
          // Eliminar el archivo de Supabase Storage
          const { error: storageError } = await supabase.storage
            .from('user_files')
            .remove([fileToRemove.file_path]);

          if (storageError) throw storageError;

          // Eliminar la entrada de la base de datos
          const { error: dbError } = await supabase
            .from('user_files')
            .delete()
            .eq('id', id);

          if (dbError) throw dbError;

          // Actualizar el estado local
          setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== id));

          console.log('Archivo eliminado con éxito:', fileToRemove.file_name);
        }
      } catch (error) {
        console.error('Error al eliminar el archivo:', error);
      }
    }
  };

  const processFileWithVector = async (fileId, filePath) => {
    if (!supabase || !userId) {
      console.error('Supabase client or userId not available');
      return false;
    }

    try {
      // 1. Fetch the file content
      const { data, error } = await supabase.storage
        .from('user_files')
        .download(filePath);

      if (error) throw error;

      // 2. Convert file content to text
      const content = await data.text();

      // 3. Generate embedding
      const embedding = await generateEmbedding(content);

      // 4. Insert the embedding into the database
      const { data: insertData, error: insertError } = await supabase
        .from('user_document_embeddings')
        .upsert({
          user_id: userId,
          file_id: fileId,
          content: content,
          embedding: embedding
        }, {
          onConflict: 'user_id, file_id',
          returning: 'minimal'
        });

      if (insertError) throw insertError;

      console.log(`Procesado exitosamente el archivo ${fileId} en ${filePath}`);
      return true;
    } catch (error) {
      console.error('Error al procesar el archivo con vector:', error);
      return false;
    }
  };

  const generateEmbedding = async (content) => {
    try {
      const response = await fetch('/api/generate-embedding', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate embedding');
      }

      const embedding = await response.json();
      return embedding;
    } catch (error) {
      console.error('Error generating embedding:', error);
      throw error;
    }
  };

  // This function will be replaced or removed as it uses LangChain
  // You may want to implement a different method for generating responses
  const generateResponse = async (prompt, context) => {
    console.log('generateResponse function needs to be reimplemented without LangChain');
    return 'Response generation is currently not available.';
  };

  const uploadToVectorDatabase = async () => {
    if (!supabase || !userId) {
      console.error('Supabase client or userId not available');
      return;
    }

    try {
      let successCount = 0;
      let failCount = 0;

      for (const file of uploadedFiles) {
        const success = await processFileWithVector(file.id, file.file_path);
        if (success) {
          successCount++;
        } else {
          failCount++;
        }
      }

      console.log(`Proceso completado. Archivos procesados con éxito: ${successCount}, Fallidos: ${failCount}`);
    
      // Actualizar la interfaz de usuario aquí, por ejemplo:
      // setProcessingStatus(`Procesados: ${successCount}, Fallidos: ${failCount}`);
    } catch (error) {
      console.error('Error al subir archivos a la base de datos vectorial:', error);
      // setProcessingStatus('Error en el procesamiento de archivos');
    }
  };

  const renderSubmenu = () => {
    switch (activeSubmenu) {
      case 'prompt':
        return (
          <div className="space-y-6">
            <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
              <h2 className="text-lg md:text-xl font-bold mb-4">Plantillas de Prompt</h2>
              <select
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                className="w-full p-2 border rounded mb-4"
              >
                <option value="">Seleccionar plantilla</option>
                {templates.map((template, index) => (
                  <option key={index} value={template.id}>{template.name}</option>
                ))}
              </select>
              <textarea 
                ref={promptRef}
                placeholder="Ingrese el prompt para el agente IA aquí..."
                value={selectedTemplate ? templates.find(t => t.id === selectedTemplate).content : ''}
                onChange={(e) => {
                  const updatedTemplates = templates.map(t => 
                    t.id === selectedTemplate ? {...t, content: e.target.value} : t
                  );
                  setTemplates(updatedTemplates);
                }}
                onKeyUp={(e) => {
                  if (e.key === '{' && e.target.value.endsWith('{{')) {
                    const cursorPosition = e.target.selectionStart;
                    const textBeforeCursor = e.target.value.slice(0, cursorPosition);
                    const textAfterCursor = e.target.value.slice(cursorPosition);
                    const suggestions = [
                      '{{nombre_empresa}}',
                      '{{descripcion_empresa}}',
                      '{{email_empresa}}',
                      '{{direccion_empresa}}',
                      '{{telefono_empresa}}'
                    ];
                    const updatedText = textBeforeCursor + suggestions.join(' ') + textAfterCursor;
                    e.target.value = updatedText;
                    e.target.selectionStart = cursorPosition;
                    e.target.selectionEnd = cursorPosition + suggestions.join(' ').length;
                    const updatedTemplates = templates.map(t => 
                      t.id === selectedTemplate ? {...t, content: updatedText} : t
                    );
                    setTemplates(updatedTemplates);
                  }
                }}
                className="w-full min-h-[150px] md:min-h-[200px] p-2 border rounded mb-4"
              />
              <div className="flex flex-col md:flex-row justify-between mb-4 space-y-2 md:space-y-0 md:space-x-2">
                <button 
                  onClick={() => {
                    const newTemplate = { id: Date.now().toString(), name: 'Nueva plantilla', content: '' };
                    setTemplates([...templates, newTemplate]);
                    setSelectedTemplate(newTemplate.id);
                  }}
                  className="bg-green-500 text-white p-2 rounded w-full md:w-auto"
                >
                  Nueva Plantilla
                </button>
                <button 
                  onClick={() => {
                    if (selectedTemplate) {
                      const updatedTemplates = templates.filter(t => t.id !== selectedTemplate);
                      setTemplates(updatedTemplates);
                      setSelectedTemplate('');
                    }
                  }}
                  className="bg-red-500 text-white p-2 rounded w-full md:w-auto"
                >
                  Eliminar Plantilla
                </button>
              </div>
              <button 
                onClick={() => {
                  // Aquí iría la lógica para guardar las plantillas en la base de datos
                  console.log('Plantillas guardadas:', templates);
                }}
                className="w-full bg-blue-500 text-white p-2 rounded"
              >
                Guardar Plantillas
              </button>
            </div>

            <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
              <h2 className="text-lg md:text-xl font-bold mb-4">Datos de la Empresa</h2>
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <FaUser className="text-gray-400" />
                  <input 
                    type="text"
                    placeholder="Tu nombre" 
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <FaBuilding className="text-gray-400" />
                  <input 
                    type="text"
                    placeholder="Nombre de la empresa" 
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <textarea 
                  placeholder="Descripción de la empresa"
                  value={companyDescription}
                  onChange={(e) => setCompanyDescription(e.target.value)}
                  className="w-full min-h-[100px] p-2 border rounded"
                />
                <div className="flex items-center space-x-2">
                  <FaPhone className="text-gray-400" />
                  <input 
                    type="text"
                    placeholder="Número de contacto" 
                    value={companyPhone}
                    onChange={(e) => setCompanyPhone(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <FaEnvelope className="text-gray-400" />
                  <input 
                    type="email"
                    placeholder="Email de contacto" 
                    value={companyEmail}
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <FaMapMarkerAlt className="text-gray-400" />
                  <input 
                    type="text"
                    placeholder="Dirección de la empresa" 
                    value={companyAddress}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
                {companyAddress && (
                  <a 
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(companyAddress)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Ver en Google Maps
                  </a>
                )}
              </div>
              <button 
                onClick={() => {
                  // Aquí iría la lógica para guardar los datos de la empresa en la base de datos
                  console.log('Datos de la empresa guardados:', {
                    companyName,
                    companyDescription,
                    companyPhone,
                    companyEmail,
                    companyAddress
                  });
                }}
                className="w-full bg-blue-500 text-white p-2 rounded mt-4"
              >
                Guardar Datos de la Empresa
              </button>
            </div>
          </div>
        );
      case 'knowledgeBase':
        return (
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
            <h2 className="text-lg md:text-xl font-bold mb-4">Knowledge Base</h2>
            <div className="flex items-center justify-center w-full mb-4">
              <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-48 md:h-64 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <FaUpload className="w-8 h-8 md:w-10 md:h-10 mb-3 text-blue-400" />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Haga clic para cargar</span> o arrastre y suelte
                  </p>
                  <p className="text-xs text-gray-500">Documentos, PDFs, etc.</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={handleFileUpload} />
              </label>
            </div>
            
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Archivos subidos:</h3>
              {uploadedFiles.map((file) => (
                <div key={file.id} className="flex items-center justify-between bg-white p-3 rounded-lg mb-2 border hover:bg-gray-50">
                  <div className="flex items-center">
                    <FaFile className="text-blue-500 mr-2" />
                    <span className="text-sm md:text-base">{file.file_name}</span>
                    <span className="text-xs md:text-sm text-gray-500 ml-2">({file.file_size} bytes)</span>
                  </div>
                  <button 
                    onClick={() => removeFile(file.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
            
            <button 
              className="w-full bg-blue-500 text-white p-2 rounded mt-4"
              onClick={uploadToVectorDatabase}
            >
              Subir a Base de Datos Vectorial
            </button>
          </div>
        );
      case 'transcriptions':
        return (
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
            <h2 className="text-lg md:text-xl font-bold mb-4">Transcripciones de Reuniones</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              {transcriptions.map((transcription) => (
                <div key={transcription.id} className="bg-gray-100 p-4 rounded-lg">
                  <h3 className="text-base md:text-lg font-semibold">{transcription.title}</h3>
                  <p className="text-xs md:text-sm text-gray-500 mb-2">{transcription.date}</p>
                  <p className="text-sm mb-4">{transcription.summary}</p>
                  <button className="w-full bg-blue-500 text-white p-2 rounded text-sm">Ver Transcripción Completa</button>
                </div>
              ))}
            </div>
          </div>
        );
      case 'whatsapp':
        return (
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
            <h2 className="text-lg md:text-xl font-bold mb-4">Integración con WhatsApp</h2>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <FaWhatsapp className="text-green-500" />
                <input 
                  type="text"
                  placeholder="Número de WhatsApp" 
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <textarea 
                placeholder="Mensaje de WhatsApp"
                value={whatsappMessage}
                onChange={(e) => setWhatsappMessage(e.target.value)}
                className="w-full min-h-[100px] p-2 border rounded"
              />
              <button 
                onClick={() => {
                  // Aquí iría la lógica para enviar el mensaje de WhatsApp
                  console.log('Enviando mensaje de WhatsApp:', {
                    number: whatsappNumber,
                    message: whatsappMessage
                  });
                }}
                className="w-full bg-green-500 text-white p-2 rounded mt-4"
              >
                Enviar Mensaje de WhatsApp
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100 min-h-screen">
      <h1 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-center text-blue-600">Configuración del Agente IA</h1>
      
      <div className={`flex flex-wrap mb-6 ${isMobile ? 'space-y-2' : 'space-x-4'}`}>
        <button
          onClick={() => setActiveSubmenu('prompt')}
          className={`px-4 py-2 rounded ${activeSubmenu === 'prompt' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} ${isMobile ? 'w-full' : ''}`}
        >
          Prompt y Datos Empresa
        </button>
        <button
          onClick={() => setActiveSubmenu('knowledgeBase')}
          className={`px-4 py-2 rounded ${activeSubmenu === 'knowledgeBase' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} ${isMobile ? 'w-full' : ''}`}
        >
          Knowledge Base
        </button>
        <button
          onClick={() => setActiveSubmenu('transcriptions')}
          className={`px-4 py-2 rounded ${activeSubmenu === 'transcriptions' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} ${isMobile ? 'w-full' : ''}`}
        >
          Transcripciones
        </button>
        <button
          onClick={() => setActiveSubmenu('whatsapp')}
          className={`px-4 py-2 rounded ${activeSubmenu === 'whatsapp' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} ${isMobile ? 'w-full' : ''}`}
        >
          WhatsApp
        </button>
      </div>

      {renderSubmenu()}
    </div>
  );
}

export default AI;
