import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaUsers, FaCalendarAlt, FaCog, FaKey, FaLink, FaCopy, FaEye, FaEyeSlash } from 'react-icons/fa';
import './Dashboard.css';

function Dashboard() {
  const [stats, setStats] = useState({
    leadCount: 0,
    appointmentCount: 0,
    serviceCount: 0
  });
  const [apiKey, setApiKey] = useState('');
  const [calendarUrl, setCalendarUrl] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState(null);
  const [icsUrl, setIcsUrl] = useState('');
  const [festivos, setFestivos] = useState([]);

  const fetchDashboardData = useCallback(async () => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }

      const [leadsResponse, appointmentsResponse, servicesResponse, userDataResponse, festivosResponse] = await Promise.all([
        axios.get('/api/leads', { headers: { Authorization: `Bearer ${token}` } }),
        axios.get('/api/citas', { headers: { Authorization: `Bearer ${token}` } }),
        axios.get('/api/servicios', { headers: { Authorization: `Bearer ${token}` } }),
        axios.get('/api/usuarios/me', { headers: { Authorization: `Bearer ${token}` } }),
        axios.get('/api/festivos', { headers: { Authorization: `Bearer ${token}` } })
      ]);

      console.log('Respuesta de leads:', leadsResponse.data);
      console.log('Respuesta de citas:', appointmentsResponse.data);
      console.log('Respuesta de servicios:', servicesResponse.data);

      setStats({
        leadCount: Array.isArray(leadsResponse.data) ? leadsResponse.data.length : 0,
        appointmentCount: Array.isArray(appointmentsResponse.data) ? appointmentsResponse.data.length : 0,
        serviceCount: Array.isArray(servicesResponse.data) ? servicesResponse.data.length : 0
      });
      
      setApiKey(token);
      
      if (userDataResponse && userDataResponse.data) {
        setUserData(userDataResponse.data);
        setIcsUrl(userDataResponse.data.ics_url || '');
        
        const RADICALE_URL = process.env.REACT_APP_RADICALE_URL || 'https://calendar.hiveagile.dev';
        if (userDataResponse.data.radicale_username && userDataResponse.data.calendar_id) {
          const calendarUrlConstructed = `${RADICALE_URL}/${userDataResponse.data.radicale_username}/${userDataResponse.data.calendar_id}/`;
          setCalendarUrl(calendarUrlConstructed);
        } else {
          setCalendarUrl('N/A');
          console.warn('Falta información del calendario del usuario', userDataResponse.data);
        }
      } else {
        console.warn('No se pudo obtener la información del usuario');
        setCalendarUrl('N/A');
      }
      
      setFestivos(Array.isArray(festivosResponse.data) ? festivosResponse.data : []);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      let errorMessage = 'Error al cargar los datos del dashboard';
      if (error.response) {
        console.error('Error response:', error.response.data);
        errorMessage += `: ${error.response.status} ${error.response.statusText}`;
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMessage += ': No se recibió respuesta del servidor';
      } else {
        console.error('Error message:', error.message);
        errorMessage += `: ${error.message}`;
      }
      setError(errorMessage);
      alert(errorMessage);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const toggleApiKeyVisibility = useCallback(() => {
    setShowApiKey((prev) => !prev);
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copiado al portapapeles');
    }, (err) => {
      console.error('Error al copiar: ', err);
    });
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="dashboard">
      <div className="stat-cards">
        <StatCard icon={FaUsers} label="Leads" value={stats.leadCount} color="#3182CE" />
        <StatCard icon={FaCalendarAlt} label="Citas" value={stats.appointmentCount} color="#38A169" />
        <StatCard icon={FaCog} label="Servicios" value={stats.serviceCount} color="#805AD5" />
      </div>

      <ApiKeySection
        apiKey={apiKey}
        showApiKey={showApiKey}
        toggleApiKeyVisibility={toggleApiKeyVisibility}
        copyToClipboard={copyToClipboard}
      />

      {calendarUrl && calendarUrl !== 'N/A' && (
        <CalendarUrlSection
          calendarUrl={calendarUrl}
          copyToClipboard={copyToClipboard}
        />
      )}
    </div>
  );
}

const ApiKeySection = React.memo(({ apiKey, showApiKey, toggleApiKeyVisibility, copyToClipboard }) => (
  <div className="section api-key-section">
    <h3><FaKey /> API Key</h3>
    <div className="input-group">
      <input
        type={showApiKey ? 'text' : 'password'}
        value={apiKey}
        readOnly
      />
      <button onClick={toggleApiKeyVisibility}>
        {showApiKey ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
    <div className="actions">
      <button onClick={() => copyToClipboard(apiKey)}>
        <FaCopy /> Copiar
      </button>
      <a href="/api-docs/" target="_blank" rel="noopener noreferrer">
        Documentación de la API
      </a>
    </div>
  </div>
));

const CalendarUrlSection = React.memo(({ calendarUrl, copyToClipboard }) => (
  <div className="section calendar-url-section">
    <h3><FaLink /> URL del Calendario</h3>
    <div className="input-group">
      <input value={calendarUrl} readOnly />
      <button onClick={() => copyToClipboard(calendarUrl)}>
        <FaCopy />
      </button>
    </div>
    <p>
      Si usas Outlook, que no es compatible con CalDAV, puedes instalar el plugin{' '}
      <a href="https://caldavsynchronizer.org/" target="_blank" rel="noopener noreferrer">
        <b>'CalDAV Synchronizer'</b>
      </a> para añadir y sincronizar fácilmente tus calendarios con
      otros dispositivos.
    </p>
    <a
      href="https://caldavsynchronizer.org/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Más información sobre CalDAV Synchronizer
    </a>
  </div>
));

const StatCard = React.memo(({ icon: Icon, label, value, color }) => (
  <div className="stat-card" style={{ '--card-color': color }}>
    <Icon size={48} color={color} />
    <div>
      <h4>{label}</h4>
      <p>{value}</p>
    </div>
  </div>
));

export default React.memo(Dashboard);
